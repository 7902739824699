// .user-form-wrapper {
//   display: flex;
//   flex: 1;
//   align-items: center;
//   justify-content: center;
//   padding: rem(50) rem(20);
// }

// .user-form {
//   flex: auto;
//   text-align: center;
//   max-width: rem(500);
//   border: rem(1) solid $color-white;
//   border-radius: rem(5);
//   box-shadow: 0 rem(1) rem(3) rgba(0,0,0,0.5);
//   padding: rem(40) rem(20);

//   #login div {
//     display: flex;
//     justify-content: center;
//   }
// }

// .user-form input {
//   width: 100%;
//   max-width: rem(300);
//   padding: rem(15) rem(25);
//   font-size: rem(14);
//   color: #9d9e9e;
//   text-shadow: rem(1) rem(1) 0 rgba(256,256,256,1.0);
//   background-color: $color-white;
//   border: rem(1) solid $color-white;
//   border-radius: rem(5);
//   box-shadow: inset 0 rem(1) rem(3) rgba(0,0,0,0.50);
//   outline: none;
//   margin-bottom: rem(20);
// }

// .user-form label {
//   display: block;
//   margin-bottom: rem(10);
//   color: map-get($color-map-admin-user, label);
// }

// .user-form__title {
//   margin: 0 0 rem(40);
// }

// #password_reset_request_submit,
// #login_submit,
// #password_reset_token_submit {
//   background-color: map-get($color-map-admin-user, green);
//   border: none;
//   padding: rem(15) rem(25);
//   color: $color-white;
//   font-size: rem(16);
//   cursor: pointer;
//   margin-bottom: rem(20);
//   border-radius: rem(5);
//   width: 100%;
//   max-width: rem(350);
//   outline: none;
// }

// #password_reset_request_submit:hover,
// #login_submit:hover,
// #password_reset_token_submit:hover {
//   opacity: 0.8;
// }

// .user-form__link {
//   color: map-get($color-map-admin-user, link);
//   text-decoration: none;
// }

// .user-form__link:hover {
//   opacity: 0.7;
//   color: map-get($color-map-admin-user, link-hover);
// }

// @include breakpoint($tablet) {
//   .user-form input {
//     width: rem(300);
//   }
// }

input[type="email"], input[type="password"] {
  background-color : transparent !important; 
}
