@import 'reset';
@import 'mixins';
@import 'variables';
@import 'general';

@import 'components/header';
@import 'components/security';
@import 'components/flashMessages';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
  .virtual-bg {
    background: radial-gradient(93.75% 93.75% at 50% 88.54%, #1D5092 0%, #020820 69.79%);
    mix-blend-mode: normal;
    box-shadow: 0px 0px 35px #2C7FE0;
    position: fixed;
  }

  .shadow-glow-blue {
    background: #2C7FE0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    filter: blur(30px);
    border-radius: 20px;
  }
}

.border-opacity {
  opacity: 0.2;
  border: 1px solid #FFFFFF;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

html {
  // overflow:  scroll;
}

::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
