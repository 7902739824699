/*
  Font variables
*/
$font-main: 'Roboto', sans-serif;

/*
  Breakpoints
*/
$xlarge: 1805px;
$mlarge: 1480px;
$large: 1279px;
$desktop: 1110px;
$tablet: 768px;

/*
  Color and color maps
*/

$color-white: #ffffff;
$color-error: red;
$color-warning: orange;
$color-success: darkseagreen;
$color-info: cornflowerblue;

// User login/password reset colors
$color-map-admin-user: (
green: #00ce81,
label:#555555,
link: #808080,
link-hover: #b23b3b
);
