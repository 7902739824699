body {
  display: flex;
  flex-direction: column;
  font-family: $font-main;
  background-color: $color-white;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
