header {
  display: flex;
  text-align: center;
  background: #ddd;
  padding: rem(20);
  align-items: center;

  @include breakpoint($tablet) {
    text-align: inherit;
    padding: rem(20) rem(50);
  }
}

.header__bar .user-form__link:hover {
  opacity: 0.7;
}

.header__bar--right {
  margin-top: rem(30);
}

.header__bar .main__title,
.header__bar p {
  padding: 0;
  margin: rem(10) 0;
}

.header__bar .main__title {
  margin: 0 auto 0 0;
}

@include breakpoint($tablet) {
  .header__bar--right {
    margin: 0 0 0 auto;
    display: flex;
  }

  .header__bar .user-form__link {
    margin-left: rem(20);
    vertical-align: middle;
  }

  .header__bar .main__title,
  .header__bar p {
    margin: 0;
  }
}
